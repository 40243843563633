import React from 'react';
import EvaluationAuth from '../screens/auth/EvaluationAuth';
import { Redirect, Router } from '@reach/router';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirebaseAuth } from '../services/firebase';

export interface EvaluationPageProps {}

const EvaluationPage: React.FunctionComponent<EvaluationPageProps> = (): JSX.Element => {
  const [user, loading, error] = useAuthState(getFirebaseAuth());

  if (loading) {
    return <></>;
  }

  return user ? (
    <Router>
      <EvaluationAuth path="/evaluation/:category" />
    </Router>
  ) : (
    <Redirect noThrow to="/" />
  );
};

export default EvaluationPage;
